import {
  logEvent,
  EVENT_PARAM_TYPE_STRING,
  EVENT_PARAM_TYPE_BOOL,
  EVENT_TYPE_INTERACTION,
} from '@artemis/utils/analytics';

const EVENT_ACTION_GROUP_ORDER_INVITE_MODAL_IMPRESSION =
  'GROUP_ORDER_GUEST_INVITE_MODAL_IMPRESSION';
const EVENT_ACTION_GROUP_ORDER_GUEST_JOIN_CLICK =
  'GROUP_ORDER_GUEST_JOIN_GROUP_CLICK';
const EVENT_ACTION_GROUP_ORDER_GUEST_DECLINE_CLICK =
  'GROUP_ORDER_GUEST_DECLINE_GROUP_CLICK';
const EVENT_ACTION_GROUP_ORDER_GUEST_VIEW_DETAILS_IMPRESSION =
  'GROUP_ORDER_GUEST_VIEW_GROUP_ORDER_IMPRESSION';
const EVENT_ACTION_GROUP_ORDER_GUEST_LEAVE_CLICK =
  'GROUP_ORDER_GUEST_LEAVE_GROUP_ORDER_CLICK';
const EVENT_ACTION_GROUP_ORDER_GUEST_PLACE_ORDER_CLICK =
  'GROUP_ORDER_GUEST_PLACE_ORDER_CLICK';
const EVENT_ACTION_GROUP_ORDER_MENU_CREATE_CLICK =
  'GROUP_ORDER_MENU_CREATE_CLICK';
const EVENT_ACTION_GROUP_ORDER_EDIT_IMPRESSION = 'GROUP_ORDER_EDIT_IMPRESSION';
const EVENT_ACTION_GROUP_ORDER_EDIT_DELIVERY_MODAL_IMPRESSION =
  'GROUP_ORDER_EDIT_DELIVERY_MODAL_IMPRESSION';
const EVENT_ACTION_GROUP_ORDER_EDIT_SCHEDULED_MODAL_IMPRESSION =
  'GROUP_ORDER_EDIT_SCHEDULED_MODAL_IMPRESSION';
const EVENT_ACTION_GROUP_ORDER_EDIT_GUEST_LIMIT_MODAL_IMPRESSION =
  'GROUP_ORDER_EDIT_GUEST_LIMIT_MODAL_IMPRESSION';
const EVENT_ACTION_GROUP_ORDER_EDIT_PAYMENT_SPLIT_MODAL_IMPRESSION =
  'GROUP_ORDER_EDIT_PAYMENT_SPLIT_MODAL_IMPRESSION';
const EVENT_ACTION_GROUP_ORDER_HOST_CHECKOUT_IMPRESSION =
  'GROUP_ORDER_HOST_CHECKOUT_IMPRESSION';
const EVENT_ACTION_GROUP_ORDER_HOST_PLACE_ORDER_CLICK =
  'GROUP_ORDER_HOST_PLACE_ORDER_CLICK';
const EVENT_ACTION_GROUP_ORDER_COPY_SHARE_LINK_CLICK =
  'GROUP_ORDER_COPY_SHARE_LINK_CLICK';
const EVENT_ACTION_GROUP_ORDER_STATUS_PAGE_IMPRESSION =
  'GROUP_ORDER_STATUS_PAGE_IMPRESSION';
const EVENT_ACTION_GROUP_ORDER_CANCEL_GROUP_CLICK =
  'GROUP_ORDER_CANCEL_GROUP_CLICK';
const EVENT_ACTION_GROUP_ORDER_INTRO_MODAL_IMPRESSION =
  'GROUP_ORDER_INTRO_MODAL_IMPRESSION';
const EVENT_ACTION_GROUP_ORDER_STATUS_PAGE_LEARN_MORE_CLICK =
  'GROUP_ORDER_STATUS_PAGE_LEARN_MORE_CLICK';

const merchantParam = ({ merchantId }) => ({
  key: 'MERCHANT_ID',
  value: merchantId,
  type: EVENT_PARAM_TYPE_STRING,
});

const fulfillmentParam = ({ fulfillmentId }) => ({
  key: 'FULFILLMENT_ID',
  value: fulfillmentId,
  type: EVENT_PARAM_TYPE_STRING,
});

const referrerParam = ({ referrer }) => ({
  key: 'REFERRER',
  value: referrer,
  type: EVENT_PARAM_TYPE_STRING,
});

const isMerchantPageSourceParam = ({ isMerchantPageSource }) => ({
  key: 'IS_MERCHANT_PAGE_SOURCE',
  value: isMerchantPageSource,
  type: EVENT_PARAM_TYPE_BOOL,
});

const sourceUrlParam = ({ sourceUrl }) => ({
  key: 'SOURCE_URL',
  value: sourceUrl,
  type: EVENT_PARAM_TYPE_STRING,
});

export const logGroupOrderInviteModalImpression = ({
  merchantId,
  fulfillmentId,
}) => {
  logEvent(
    EVENT_ACTION_GROUP_ORDER_INVITE_MODAL_IMPRESSION,
    [merchantParam({ merchantId }), fulfillmentParam({ fulfillmentId })],
    EVENT_TYPE_INTERACTION,
  );
};

export const logGroupOrderJoinClick = ({ merchantId, fulfillmentId }) => {
  logEvent(
    EVENT_ACTION_GROUP_ORDER_GUEST_JOIN_CLICK,
    [merchantParam({ merchantId }), fulfillmentParam({ fulfillmentId })],
    EVENT_TYPE_INTERACTION,
  );
};

export const logGroupOrderDeclineClick = ({ merchantId, fulfillmentId }) => {
  logEvent(
    EVENT_ACTION_GROUP_ORDER_GUEST_DECLINE_CLICK,
    [merchantParam({ merchantId }), fulfillmentParam({ fulfillmentId })],
    EVENT_TYPE_INTERACTION,
  );
};

export const logGroupOrderDetailsModalImpression = ({
  merchantId,
  fulfillmentId,
}) => {
  logEvent(
    EVENT_ACTION_GROUP_ORDER_GUEST_VIEW_DETAILS_IMPRESSION,
    [merchantParam({ merchantId }), fulfillmentParam({ fulfillmentId })],
    EVENT_TYPE_INTERACTION,
  );
};

export const logGroupOrderLeaveClick = ({ merchantId, fulfillmentId }) => {
  logEvent(
    EVENT_ACTION_GROUP_ORDER_GUEST_LEAVE_CLICK,
    [merchantParam({ merchantId }), fulfillmentParam({ fulfillmentId })],
    EVENT_TYPE_INTERACTION,
  );
};

export const logGroupOrderPlaceOrderClick = ({ merchantId, fulfillmentId }) => {
  logEvent(
    EVENT_ACTION_GROUP_ORDER_GUEST_PLACE_ORDER_CLICK,
    [merchantParam({ merchantId }), fulfillmentParam({ fulfillmentId })],
    EVENT_TYPE_INTERACTION,
  );
};

export const logGroupOrderMenuCreateClick = ({ merchantId }) => {
  logEvent(
    EVENT_ACTION_GROUP_ORDER_MENU_CREATE_CLICK,
    [merchantParam({ merchantId })],
    EVENT_TYPE_INTERACTION,
  );
};

export const logGroupOrderEditImpression = ({
  merchantId,
  fulfillmentId,
  referrer,
}) => {
  logEvent(
    EVENT_ACTION_GROUP_ORDER_EDIT_IMPRESSION,
    [
      merchantParam({ merchantId }),
      fulfillmentParam({ fulfillmentId }),
      referrerParam({ referrer }),
    ],
    EVENT_TYPE_INTERACTION,
  );
};

export const logGroupOrderDeliveryModalImpression = ({
  merchantId,
  fulfillmentId,
}) => {
  logEvent(
    EVENT_ACTION_GROUP_ORDER_EDIT_DELIVERY_MODAL_IMPRESSION,
    [merchantParam({ merchantId }), fulfillmentParam({ fulfillmentId })],
    EVENT_TYPE_INTERACTION,
  );
};

export const logGroupOrderScheduledModalImpression = ({
  merchantId,
  fulfillmentId,
}) => {
  logEvent(
    EVENT_ACTION_GROUP_ORDER_EDIT_SCHEDULED_MODAL_IMPRESSION,
    [merchantParam({ merchantId }), fulfillmentParam({ fulfillmentId })],
    EVENT_TYPE_INTERACTION,
  );
};

export const logGroupOrderGuestLimitModalImpression = ({
  merchantId,
  fulfillmentId,
}) => {
  logEvent(
    EVENT_ACTION_GROUP_ORDER_EDIT_GUEST_LIMIT_MODAL_IMPRESSION,
    [merchantParam({ merchantId }), fulfillmentParam({ fulfillmentId })],
    EVENT_TYPE_INTERACTION,
  );
};

export const logGroupOrderPaymentSplitModalImpression = ({
  merchantId,
  fulfillmentId,
}) => {
  logEvent(
    EVENT_ACTION_GROUP_ORDER_EDIT_PAYMENT_SPLIT_MODAL_IMPRESSION,
    [merchantParam({ merchantId }), fulfillmentParam({ fulfillmentId })],
    EVENT_TYPE_INTERACTION,
  );
};

export const logGroupOrderHostCheckoutImpression = ({
  merchantId,
  fulfillmentId,
}) => {
  logEvent(
    EVENT_ACTION_GROUP_ORDER_HOST_CHECKOUT_IMPRESSION,
    [merchantParam({ merchantId }), fulfillmentParam({ fulfillmentId })],
    EVENT_TYPE_INTERACTION,
  );
};

export const logGroupOrderHostPlaceOrderClick = ({
  merchantId,
  fulfillmentId,
}) => {
  logEvent(
    EVENT_ACTION_GROUP_ORDER_HOST_PLACE_ORDER_CLICK,
    [merchantParam({ merchantId }), fulfillmentParam({ fulfillmentId })],
    EVENT_TYPE_INTERACTION,
  );
};

export const logGroupOrderCopyShareLinkClick = ({
  merchantId,
  fulfillmentId,
}) => {
  logEvent(
    EVENT_ACTION_GROUP_ORDER_COPY_SHARE_LINK_CLICK,
    [merchantParam({ merchantId }), fulfillmentParam({ fulfillmentId })],
    EVENT_TYPE_INTERACTION,
  );
};

export const logGroupOrderStatusPageImpression = ({
  merchantId,
  fulfillmentId,
}) => {
  logEvent(
    EVENT_ACTION_GROUP_ORDER_STATUS_PAGE_IMPRESSION,
    [merchantParam({ merchantId }), fulfillmentParam({ fulfillmentId })],
    EVENT_TYPE_INTERACTION,
  );
};

export const logGroupOrderCancelGroupClick = ({
  merchantId,
  fulfillmentId,
}) => {
  logEvent(
    EVENT_ACTION_GROUP_ORDER_CANCEL_GROUP_CLICK,
    [merchantParam({ merchantId }), fulfillmentParam({ fulfillmentId })],
    EVENT_TYPE_INTERACTION,
  );
};

export const logGroupOrderIntroModalImpression = ({
  merchantId,
  isMerchantPageSource,
}) => {
  logEvent(
    EVENT_ACTION_GROUP_ORDER_INTRO_MODAL_IMPRESSION,
    [
      merchantParam({ merchantId }),
      isMerchantPageSourceParam({ isMerchantPageSource }),
      sourceUrlParam({
        sourceUrl:
          isMerchantPageSource === true ? document.location.href : null,
      }),
    ],
    EVENT_TYPE_INTERACTION,
  );
};

export const logGroupOrderStatusPageLearnMoreClick = ({
  merchantId,
  fulfillmentId,
}) => {
  logEvent(
    EVENT_ACTION_GROUP_ORDER_STATUS_PAGE_LEARN_MORE_CLICK,
    [merchantParam({ merchantId }), fulfillmentParam({ fulfillmentId })],
    EVENT_TYPE_INTERACTION,
  );
};
