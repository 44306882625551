import initialTheme from '@artemis/theme';
import { useRouter } from 'next/router';
import merge from 'deepmerge';
import { THEME_JSON_PARAM } from './query/constants';

export default function useDerivedTheme(pageThemes) {
  const { query } = useRouter();
  let themeCopy = merge.all([initialTheme, ...pageThemes]);
  if (query[THEME_JSON_PARAM]) {
    try {
      const themeJSON = JSON.parse(query[THEME_JSON_PARAM]);
      themeCopy = merge(themeCopy, themeJSON);
    } catch {
      // Do nothing
    }
  }
  if (themeCopy.primaryColorHex) {
    themeCopy.palette.primary = themeCopy.primaryColorHex;
  }
  return themeCopy;
}
