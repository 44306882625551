/**
 *
 * Button
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';
import { lighten } from 'polished';
import { LoadingSpinner } from '@ritualco/ritual-frontend-components';

const getColor = props => {
  if (props.color === 'primary') {
    return props.theme.palette.primary;
  }
  if (props.color === 'secondary') {
    return props.theme.palette.secondary;
  }
  if (props.color === 'grey') {
    return props.theme.palette.grey[600];
  }
  return props.theme.rtColors.black1000;
};

const getHoverBackgroundColor = props => {
  if (props.disabled) {
    return 'none;';
  }
  switch (props.buttonType) {
    case 'outline': {
      if (props.color === 'black') {
        return props.theme.rtColors.black100;
      }
      return `${lighten(0.51, getColor(props))};`;
    }
    case 'ghost': {
      if (props.color === 'black') {
        return props.theme.rtColors.black100;
      }
      return `${lighten(0.51, getColor(props))};`;
    }
    case 'solid': {
      return `${props.theme.palette.hover.onLightBg(getColor(props))}`;
    }
    default:
      return 'none;';
  }
};

const Container = styled.button`
  background: none;
  min-width: 160px;
  border: ${props =>
    props.buttonType === 'outline' ? `1px solid ${getColor(props)};` : 'none;'};
  background-color: ${props => props.buttonType === 'solid' && getColor(props)};
  color: ${props =>
    props.buttonType !== 'solid'
      ? getColor(props)
      : props.theme.palette.common.white};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  cursor: pointer;
  &:disabled {
    cursor: default;
    ${props =>
      props.buttonType === 'solid'
        ? `background-color: ${props.theme.rtColors.black300}`
        : 'opacity: 0.6;'}
  }
  transition: 0.25s background-color ease;
  padding: 10px 30px;
  border-radius: ${props => (props.rounded ? '1000pt' : '4pt')};
  display: flex;
  justify-content: center;
  &:hover {
    text-decoration: ${props =>
      props.buttonType === 'link' && !props.disabled ? 'underline' : 'none'};
    background-color: ${props => getHoverBackgroundColor(props)};
  }
`;

const SIZES = {
  small: 12,
  default: 16,
  large: 20,
};

const SpinnerContainer = styled.div`
  position: absolute;
  ${props =>
    props.position === 'left'
      ? `left: -${SIZES[props.size] * 1.5}px;`
      : `right: -${SIZES[props.size] * 1.5}px;`}
  top: 50%;
  transform: translateY(-50%);
  display: flex;
`;

const TextContainer = styled.div`
  position: relative;
  display: inline-block;
  color: inherit;
  font-weight: inherit;
`;

/**
 * Button component description
 */
const Button = ({
  buttonType = 'link',
  color = 'primary',
  isLoading = false,
  isDisabled = false,
  children,
  rounded = false,
  loaderSize = 'default',
  loaderPosition = 'left',
  ...props
}) => {
  const theme = useTheme();
  return (
    <Container
      buttonType={buttonType}
      disabled={isDisabled || isLoading}
      color={color}
      rounded={rounded}
      {...props}
    >
      <TextContainer>
        {isLoading && (
          <SpinnerContainer
            role="alert"
            aria-busy="true"
            size={loaderSize}
            position={loaderPosition}
          >
            {buttonType === 'solid' ? (
              <LoadingSpinner size={SIZES[loaderSize]} />
            ) : (
              <LoadingSpinner
                size={SIZES[loaderSize]}
                colour={getColor({ color, theme })}
              />
            )}
          </SpinnerContainer>
        )}
        {children}
      </TextContainer>
    </Container>
  );
};

Button.propTypes = {
  buttonType: PropTypes.oneOf(['link', 'ghost', 'outline', 'solid']),
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  rounded: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary', 'grey', 'black']),
  loaderSize: PropTypes.oneOf(['default', 'small', 'large']),
  loaderPosition: PropTypes.oneOf(['left', 'right']),
};

export default Button;
