import { HQO_LINKOUT_PARAM } from '@artemis/utils/query/constants';
import useQueryCheck from '@artemis/utils/query/useQueryCheck';

/**
 * In some contexts HqO loads ROO webviews without the full SDK integration (for example on their web app)
 * When the "hqoLinkout" query parameter is present we hide login CTAs and certain payment options.
 *
 * See: https://ritualco.atlassian.net/wiki/spaces/HW/pages/2501509121/How+the+HqO+Integration+Works#Linkout-Integration
 */
const useIsHqOLinkout = () => {
  const isHqoLinkout = useQueryCheck(HQO_LINKOUT_PARAM);
  return isHqoLinkout;
};

export default useIsHqOLinkout;
