import PropTypes from 'prop-types';
import { FULFILLMENT_TYPE, PAYMENT_SPLIT_TYPE } from '@artemis/utils/constants';

export const LocationShape = PropTypes.shape({
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  city: PropTypes.string,
  province: PropTypes.string,
  provinceCode: PropTypes.string,
  country: PropTypes.string,
  cityCode: PropTypes.string,
  callingCountryCode: PropTypes.string,
  googlePlaceId: PropTypes.string,
});

export const DeliveryDetailsShape = PropTypes.shape({
  locationId: PropTypes.string,
  location: LocationShape,
});

export const PaymentSplitShape = PropTypes.shape({
  paymentType: PropTypes.oneOf(Object.values(PAYMENT_SPLIT_TYPE)),
  payPerPersonMicro: PropTypes.number,
});

export const GroupDisplayShape = PropTypes.shape({
  name: PropTypes.string,
  imageUrl: PropTypes.string,
});

export const FulfillmentType = PropTypes.oneOf([
  FULFILLMENT_TYPE.DELIVERY,
  FULFILLMENT_TYPE.IN_STORE,
]);

export const MenuItemShape = PropTypes.shape({
  menuItemId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  finalPriceMicro: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  notes: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      priceMicro: PropTypes.number,
    }),
  ),
});

export const BasketShape = PropTypes.shape({
  basketId: PropTypes.string,
  currencyCode: PropTypes.string,
  menuItems: PropTypes.arrayOf(MenuItemShape),
  partNumber: PropTypes.number,
});

export const GuestShape = PropTypes.shape({
  externalUserId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastInitial: PropTypes.string.isRequired,
  profilePictureUrl: PropTypes.string.isRequired,
  basket: BasketShape,
});

export const CostBreakdownShape = PropTypes.shape({
  totalMicro: PropTypes.number.isRequired,
  currencyCode: PropTypes.string.isRequired,
  lineItems: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      costMicro: PropTypes.number.isRequired,
    }),
  ),
});

export const ErrorType = PropTypes.shape({
  code: PropTypes.string,
  details: PropTypes.string,
  parameter: PropTypes.string,
});
