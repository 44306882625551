import React, { useRef, useState, useEffect, useContext } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Image from 'next/image';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { useSelector, useDispatch } from 'react-redux';
import { useEffectOnce } from 'react-use';
import styled, { ThemeProvider, css } from 'styled-components';
import { openModal } from '@artemis/store/fulfillment/slice';
import MenuGroup from '@artemis/components/MenuGroup';
import NavBar from '@artemis/components/NavBar';
import StoreInfo from '@artemis/containers/Menu/StoreInfo';
import {
  getMenuIsLoading,
  getMenuGroupsWithItems,
} from '@artemis/store/menu/selectors';
import {
  getReorderItems,
  getIsReorderItemsLoading,
} from '@artemis/store/catalog/selectors';
import Metadata from '@artemis/components/Metadata';
import {
  getMerchantData,
  getMerchantSupportsDelivery,
  getMerchantName,
  getMerchantTheme,
  getMerchantUrl,
  getMerchantAuthMode,
  getMerchantId,
  getMerchantUnlisted,
  getShouldSuppressSearchIndexing,
} from '@artemis/store/merchant/selectors';
import { openItem } from '@artemis/store/menu/slice';
import { loadCart } from '@artemis/store/cart/slice';
import { setInviteModal } from '@artemis/store/groupOrder/slice';
import {
  getCanOrderNow,
  getCanViewMobileCart,
  getCartHasItems,
  getCartLoading,
  getCartSubtotal,
  getItemsInCart,
  getDeliveryAddressDetail,
} from '@artemis/store/cart/selectors';
import { getOrderSource } from '@artemis/store/order/selectors';
import {
  getActiveCurrencyCredits,
  hasActiveCurrencyCredits,
} from '@artemis/store/user/selectors';
import { getFulfillmentId } from '@artemis/store/groupOrder/selectors';
import {
  FormattedMessage,
  useFormatMessage,
} from '@artemis/integrations/contentful/utils';
import ScrollableTabs from '@artemis/containers/Menu/ScrollableTabs';
import useDerivedTheme from '@artemis/utils/useDerivedTheme';
import Loading from '@artemis/components/Loading';
import { page } from '@artemis/utils/analytics';
import { FULFILLMENT_GUEST_STATUS } from '@artemis/utils/constants';
import AccountControls from '@artemis/containers/AccountControls';
import { BodySmall } from '@ritualco/ritual-frontend-components/build/components/Typography';
import { useSetSourceCookie } from '@artemis/utils/orderSource';
import useIsDeliveryOnly from '@artemis/containers/Cart/useIsDeliveryOnly';
import useHandleCheckoutClick from '@artemis/containers/Cart/useHandleCheckoutClick';
import useFindAndLoadFulfillment from '@artemis/containers/GroupOrder/useFindAndLoadFulfillment';
import GroupOrderContainer from '@artemis/containers/GroupOrder/GroupOrderContainer';
import { useLoadDeliveryAddresses } from '@artemis/utils/useLoadDeliveryAddresses';
import { useLoadMerchantDeliveryInfo } from '@artemis/utils/useLoadMerchantDeliveryInfo';
import useConfigureCartForCurrentContext from '@artemis/containers/GroupOrder/useConfigureCartForCurrentContext';
import { useRemoveQueryParameter } from '@artemis/utils/query';
import {
  IN_STORE_PARAM,
  LIST_TYPE_PARAM,
  MENU_DEBUG_PARAM,
  MENU_GROUP_ORDER_INVITE_PARAM,
  MENU_ITEM_ID_QUERY_PARAM,
} from '@artemis/utils/query/constants';
import useQueryCheck from '@artemis/utils/query/useQueryCheck';
import useLoadInvite from '@artemis/utils/useLoadInvite';
import useTrackWebVitals, { PAGE_NAME } from '@artemis/utils/useTrackWebVitals';
import AuthenticationContext from '@artemis/integrations/auth/AuthenticationContext';
import useIsSAPWorkspace from '@artemis/utils/query/useIsSAPWorkspace';
import {
  useDownloadAppEnabled,
  useDownloadAppModal,
} from '@artemis/utils/downloadApp';
import { useCollectGfoConversionCookie } from '@artemis/utils/gfo';
import { loadReorderItems } from '@artemis/store/catalog/slice';
import RemoteConfigContext from '@artemis/integrations/remoteConfig/context';
import { logImpression } from './analytics';
import OrderOptions from './OrderOptions';
import LanguageSelector from './LanguageSelector';
import BackButton from './BackButton';
import useCanMobileCartGoDirectToCheckout from './useCanMobileCartGoDirectToCheckout';
import useGroupOrderHostInfoModal from './useGroupOrderHostInfoModal';
import usePersistLocalSelections from './usePersistLocalSelections';
import ExpressServiceModal from './ExpressServiceModal';

import 'react-loading-skeleton/dist/skeleton.css';
import { QuickReorder } from '../LandingPage/QuickReorder';

const GroupOrderHostInfoModal = dynamic(() =>
  import('@artemis/components/GroupOrder/GroupOrderInfoModal/HostInfoModal'),
);
const GroupOrderGuestInfoModal = dynamic(() =>
  import('@artemis/components/GroupOrder/GroupOrderInfoModal/GuestInfoModal'),
);
const PerkConfirmModal = dynamic(() =>
  import('@artemis/containers/PerkConfirmModal'),
);
const GroupOrderInviteModal = dynamic(() =>
  import('@artemis/containers/GroupOrder/GroupOrderInviteModalContainer'),
);
const DownloadAppModal = dynamic(() =>
  import('@artemis/components/DownloadApp/DownloadAppModal'),
);

const Cart = dynamic(() => import('@artemis/containers/Cart'), {
  ssr: false,
  loading: () => (
    <CartSkeleton>
      <Skeleton height={400} borderRadius="4pt" />
    </CartSkeleton>
  ),
});
const GroupOrderDetailsModal = dynamic(() =>
  import('@artemis/containers/GroupOrder/GroupOrderDetailsModal'),
);
const GroupOrderLeaveModal = dynamic(() =>
  import('@artemis/containers/GroupOrder/GroupOrderLeaveModal'),
);
const GroupOrderNonjoinableModal = dynamic(() =>
  import('@artemis/containers/GroupOrder/GroupOrderNonjoinableModal'),
);
const GroupOrderJoinModal = dynamic(() =>
  import('@artemis/containers/GroupOrder/JoinGroupOrderModal'),
);
const MissedGroupOrderModal = dynamic(() =>
  import('@artemis/containers/GroupOrder/MissedGroupOrderModal'),
);
const RemovedFromGroupModal = dynamic(() =>
  import('@artemis/containers/GroupOrder/RemovedFromGroupModal'),
);
const LoyaltyCard = dynamic(() => import('@artemis/containers/LoyaltyCard'), {
  ssr: false,
  loading: () => <div style={{ gridArea: 'loyalty' }} />,
});
const MenuItemModalContainer = dynamic(() =>
  import('@artemis/containers/MenuItemModal'),
);
const MenuBanner = dynamic(() => import('./Banner'), { ssr: false });
const CartModal = dynamic(() => import('./CartModal'));
const MerchantStateAlert = dynamic(() => import('./MerchantStateAlert'), {
  ssr: false,
  loading: () => <div style={{ gridArea: 'merchant-state' }} />,
});
const ServiceFeeModal = dynamic(() =>
  import('@artemis/containers/Menu/ServiceFeeModal'),
);

const xSpacing = 16;

const MenuDisplay = styled.main`
  padding: 16px 0px;
  background: ${props => props.theme.palette.background.card};
  max-width: 1204px;
  margin: 0 auto;
  margin-top: ${props => (props.showImage ? '-20px' : '10px')};
  border-radius: 20px 20px 0px 0px;
  box-sizing: border-box;
  ${({ theme }) => theme.noScrollbars};
  ${({ theme, showImage }) => theme.isTablet`
    border-radius: 20px 20px 0px 0px;
    margin-top: ${showImage ? '-120px' : '10px'};
    padding: 30px 14px;
    display: grid;
    grid-template-areas:
      "store-info         merchant-state"
      "store-info         cart"
      "order-tools        cart"
      "loyalty            cart"
      "language-selector  cart"
      "groups             cart";
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 30px;
    grid-template-rows: auto min-content auto auto auto;
    `}

  ${({ theme }) => theme.isDesktop`
      grid-template-areas:
      "store-info         merchant-state"
      "store-info         loyalty"
      "store-info         cart"
      "order-tools        cart"
      "language-selector  cart"
      "groups             cart";
      grid-template-rows: auto;
  `};
`;

const CartText = styled.span`
  ${props => props.theme.typography.h6};
  color: ${({ theme }) => theme.palette.common.white};
`;

const CartTextSmall = styled(CartText)`
  ${props => props.theme.typography.body};
`;

const ToggleMenuContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.common.white};
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ theme }) => theme.isTablet`
    display: none;
  `};
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.24);
`;

const CreditsDisplayCart = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 16px 0;
  background-color: ${({ theme }) => theme.palette.common.white};
`;

const CreditsDisplayCartText = styled(BodySmall)`
  color: ${({ theme }) => theme.palette.common.black};
`;

const ToggleMenu = styled.button`
  align-items: center;
  background: ${({ theme }) => theme.palette.primary};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: space-between;
  margin: 16px 16px 40px;
  padding: 0 16px;
  transition: 0.25s background-color ease;
  z-index: 1;
  &:hover,
  &:focus {
    background-color: ${props =>
      props.theme.palette.hover.onLightBg(props.theme.palette.primary)};
  }
`;

const CreditsDisplayContainer = styled.div`
  background: ${({ theme }) => theme.palette.common.white};
  bottom: 0;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  border: none;
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.24);

  ${({ theme }) => theme.isTablet`
    display: none;
  `};
`;

const CreditsText = styled.p`
  padding: 10px;
  ${props => props.theme.typography.bodySmall};
`;

const StyledCart = styled(Cart)`
  // Do not remove this !important. Strange bug where these styles do not take
  // precedence over the base component styles...
  display: none !important;
  ${({ theme }) => theme.isTablet`
    height: 100%;
    display: flex !important;
    box-sizing: content-box;
    height: min-content;
    max-height: 70vh;
    position: sticky;
    top: 100px;
    grid-area: cart;
    transform: none;
  `};
`;

const CartSkeleton = styled.div`
  display: none;
  ${({ theme }) => theme.isTablet`
    display: block;
    box-sizing: content-box;
    height: 400px;
    position: sticky;
    top: 100px;
    grid-area: cart;
  `};
`;

const Page = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.isTablet`
    overflow-y: hidden;
  `};
`;

const Content = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  ${({ theme }) => theme.noScrollbars};
  max-width: 1920px;
  margin: 0 auto;
`;

const MenuGroupTabs = styled.div`
  grid-area: groups;
  min-width: 0;
`;

const MerchantState = styled(MerchantStateAlert)`
  margin-bottom: 32px;
  ${({ theme }) => theme.isTablet`
    grid-area: merchant-state;
    position: sticky;
    margin-bottom: 24px;
  `};
`;

const BackgroundImage = styled.div`
  position: relative;
  z-index: -1;
  width: 100%;
  height: 120px;
  img {
    object-fit: cover;
  }
  ${({ theme }) => theme.isTablet`
    height: 300px;
    object-fit: cover;
  `};
`;

const StyledStoreInfo = styled(StoreInfo)`
  grid-area: store-info;
  padding: 0 ${xSpacing}px 16px;

  ${({ theme }) => theme.isTablet`
    grid-area: store-info;
  `};
`;

const StyledLoading = styled(Loading)`
  ${({ theme }) => theme.isTablet`
    visibility: hidden;
  `};
`;

const FlexPadding = styled.div`
  flex: 1;
`;

const orderToolsCss = css`
  margin-bottom: 16px;
  grid-area: order-tools;
`;

const StyledOrderOptions = styled(OrderOptions)`
  margin-bottom: 32px;
  padding: 0 16px;
  grid-area: order-options;
  ${orderToolsCss}
`;

const GroupOrderWrapper = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  ${orderToolsCss}
`;

const StyledLoyaltyCard = styled(LoyaltyCard)`
  margin: 0 ${xSpacing}px 0;
  ${({ theme }) => theme.isTablet`
    margin: 0 ${xSpacing}px 24px;
    grid-area: loyalty;
  `}

  ${({ theme }) => theme.isDesktop`
    margin: 0 0 12px;
  `}
`;

const MenuContainer = ({
  isPreview = false,
  overrideMerchantName,
  overrideMerchantAddress,
}) => {
  const { initialized } = useContext(AuthenticationContext);

  const isMenuLoading = useSelector(getMenuIsLoading);
  const menuGroups = useSelector(getMenuGroupsWithItems);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const name = overrideMerchantName || useSelector(getMerchantName);
  const cartLoading = useSelector(getCartLoading);
  const cartSubtotal = useSelector(getCartSubtotal);
  const url = useSelector(getMerchantUrl);
  const canOrderNow = useSelector(getCanOrderNow);
  const canViewMobileCart = useSelector(getCanViewMobileCart);
  const hasItems = useSelector(getCartHasItems);
  const itemsInCart = useSelector(getItemsInCart);
  const hasCredits = useSelector(hasActiveCurrencyCredits);
  const userCredits = useSelector(getActiveCurrencyCredits);
  const merchantId = useSelector(getMerchantId);
  const merchantTheme = useSelector(getMerchantTheme);
  const merchantData = useSelector(getMerchantData);
  const addressDetail = useSelector(getDeliveryAddressDetail);
  const merchantAuthMode = useSelector(getMerchantAuthMode);
  const isMerchantUnlisted = useSelector(getMerchantUnlisted);
  const fulfillmentId = useSelector(getFulfillmentId);
  const impressionSource = useSelector(getOrderSource);
  const shouldSuppressSearchIndexing = useSelector(
    getShouldSuppressSearchIndexing,
  );
  const reorderItems = useSelector(getReorderItems);
  const isReorderItemsLoading = useSelector(getIsReorderItemsLoading);

  const removeQueryParameter = useRemoveQueryParameter();
  const derivedTheme = useDerivedTheme([merchantTheme]);
  const menuRef = useRef();
  const scrollToRef = useRef();
  const dispatch = useDispatch();

  const { getBoolean } = useContext(RemoteConfigContext);
  const isQuickOrderMenuEnabled = getBoolean('rw_menu_quick_reorder');

  const [isCartOpen, setIsCartOpen] = useState(false);
  const requestCartClose = () => setIsCartOpen(false);

  const mobileCartText = `${useFormatMessage({
    entry: 'menu.cart.name',
  })} (${itemsInCart})`;
  const mobileCheckoutText = useFormatMessage({
    entry: 'menu.cart.checkout',
  });

  useCollectGfoConversionCookie();
  usePersistLocalSelections();
  useFindAndLoadFulfillment({
    merchantId,
    guestStatus: FULFILLMENT_GUEST_STATUS.BROWSING,
  });
  useConfigureCartForCurrentContext();
  useLoadInvite();
  useGroupOrderHostInfoModal();
  useTrackWebVitals(PAGE_NAME.MENU);
  const canMobileCartGoDirectToCheckout = useCanMobileCartGoDirectToCheckout();
  const { handleCheckoutClick } = useHandleCheckoutClick();
  const canShowMobileCart =
    hasItems && (canViewMobileCart || !canMobileCartGoDirectToCheckout);

  const suppressIndexing = shouldSuppressSearchIndexing || isMerchantUnlisted;

  useEffect(() => {
    // preview page contains its own analytics
    const source = new URL(window.location).searchParams.get(LIST_TYPE_PARAM);
    if (!isPreview) {
      logImpression({
        merchantData,
        source,
        merchantAuthMode,
        impressionSource,
        userAgent: window.navigator.userAgent,
      });
      page({
        MERCHANT_ID: merchantData?.id,
      });
    }
  }, []);

  useEffect(() => {
    if (merchantId && isQuickOrderMenuEnabled) {
      dispatch(
        loadReorderItems({
          merchantId,
        }),
      );
    }
  }, [merchantId, isQuickOrderMenuEnabled]);

  const pageTitle = useFormatMessage({
    entry: 'menu.html.title',
    values: { name },
  });

  const pageDescription = useFormatMessage({ entry: 'menu.html.description' });
  const { query } = useRouter();
  const queryParamItemId = query[MENU_ITEM_ID_QUERY_PARAM];
  const [showGroupOrderInviteModal, hasInStoreParam, isDebugMode] =
    useQueryCheck([
      MENU_GROUP_ORDER_INVITE_PARAM,
      IN_STORE_PARAM,
      MENU_DEBUG_PARAM,
    ]);
  const pageImageRoot = derivedTheme.headerImageUrl;
  const pageImage = pageImageRoot ? `${pageImageRoot}=w1080-h540-c` : false;
  const pageUrl = `https://ritual.co${url}`;

  const isDeliveryOnly = useIsDeliveryOnly();

  const isFromSapWorkspaceList = useIsSAPWorkspace();
  const supportsDelivery =
    useSelector(getMerchantSupportsDelivery) && !isFromSapWorkspaceList;

  const hidePickup = isDeliveryOnly && supportsDelivery;
  const setSourceCookie = useSetSourceCookie();

  const isDownloadAppEnabled = useDownloadAppEnabled();
  const [isDownloadAppModalOpen, onDownloadAppModalClose] =
    useDownloadAppModal();
  const appleItunesApp =
    isDownloadAppEnabled && !isDownloadAppModalOpen
      ? `app-id=${process.env.RT_APPLE_APP_STORE_ID}, app-argument=ritualco://ritnav/browse?mid=${merchantId}`
      : undefined;

  useEffectOnce(() => {
    // Set a cookie with order source information on initial page load
    setSourceCookie();

    if (!isPreview) {
      // Load the cart data
      dispatch(loadCart({ merchantId }));
    }
  });

  useEffect(() => {
    if (hasInStoreParam && initialized) {
      scrollToRef.current.scrollIntoView();
    }
  }, [hasInStoreParam, initialized]);

  useEffect(() => {
    // open item modal if item id is specified as query param
    if (queryParamItemId) {
      dispatch(openItem(queryParamItemId));
    }
  }, [queryParamItemId]);

  useEffect(() => {
    if (showGroupOrderInviteModal) {
      dispatch(setInviteModal({ isOpen: true }));
      removeQueryParameter(MENU_GROUP_ORDER_INVITE_PARAM);
    }
  }, [showGroupOrderInviteModal]);

  useLoadMerchantDeliveryInfo();
  useLoadDeliveryAddresses();

  const openGraphTwitterTags = {
    ...(pageUrl && { url: pageUrl }),
    siteName: 'Ritual',
    ...(pageTitle && { title: pageTitle }),
    ...(pageDescription && { description: pageDescription }),
    ...(pageImage && {
      image: {
        url: pageImage,
      },
      card: 'summary_large_image',
    }),
  };

  const handleMobileCartClick = e => {
    if (canMobileCartGoDirectToCheckout && canOrderNow) {
      handleCheckoutClick(e);
    } else {
      setIsCartOpen(true);
    }
  };

  return (
    <Page id="root">
      <StyledLoading isLoading={cartLoading} />
      <Metadata
        title={pageTitle}
        canonical={pageUrl}
        description={pageDescription}
        openGraph={openGraphTwitterTags}
        twitter={openGraphTwitterTags}
        noindex={suppressIndexing}
        nofollow={suppressIndexing}
        appleItunesApp={appleItunesApp}
      />
      <ThemeProvider theme={derivedTheme}>
        <Content
          data-testid="content-container"
          id="content-container"
          ref={menuRef}
        >
          {isPreview || (
            <NavBar>
              <BackButton />
              <FlexPadding />
              <AccountControls />
            </NavBar>
          )}
          <MenuBanner />
          {derivedTheme.headerImageUrl && (
            <BackgroundImage className="header--image">
              <Image
                loader={({ src, width, height }) => {
                  if (!height && !width) {
                    return `${src}`;
                  }
                  if (!height) {
                    return `${src}=w${width}`;
                  }
                  if (!width) {
                    return `${src}=w${height}`;
                  }
                  return `${src}=w${width}-h${height}`;
                }}
                src={derivedTheme.headerImageUrl}
                layout="fill"
                priority
              />
            </BackgroundImage>
          )}
          <MenuDisplay
            data-testid="menu-display"
            showImage={!!derivedTheme.headerImageUrl}
          >
            <StyledStoreInfo
              className="menu--storeInfo"
              overrideMerchantName={overrideMerchantName}
              isDebugMode={isDebugMode}
              hasInStoreParam={hasInStoreParam}
            />
            <MerchantState />
            {fulfillmentId ? (
              <GroupOrderWrapper>
                <GroupOrderContainer />
              </GroupOrderWrapper>
            ) : (
              <StyledOrderOptions
                className="menu--orderOptions"
                supportsDelivery={supportsDelivery}
                hidePickup={hidePickup}
                hasInStoreParam={hasInStoreParam}
              />
            )}
            <StyledLoyaltyCard />
            {!isPreview && <LanguageSelector />}
            {isQuickOrderMenuEnabled && (
              <QuickReorder
                items={reorderItems}
                isLoading={isReorderItemsLoading}
                navigateToCheckout={false}
              />
            )}
            <MenuGroupTabs ref={scrollToRef}>
              <ScrollableTabs
                containerRef={menuRef}
                isMenuLoading={isMenuLoading}
                tabs={menuGroups.map(group => ({
                  label: group.title,
                  value: group.id,
                  tab: (
                    <MenuGroup
                      key={`menuGroup_${group.id}`}
                      onItemSelect={itemId => {
                        // If no address and pickup
                        if (hidePickup && !addressDetail) {
                          dispatch(openModal());
                        } else {
                          dispatch(openItem(itemId, group.title));
                        }
                      }}
                      isDebugMode={isDebugMode}
                      {...group}
                    />
                  ),
                }))}
              />
            </MenuGroupTabs>
            <StyledCart
              isPreview={isPreview}
              overrideMerchantAddress={overrideMerchantAddress}
            />
          </MenuDisplay>
        </Content>
        {!hasItems && hasCredits && (
          <CreditsDisplayContainer>
            <CreditsText>
              <FormattedMessage
                entry="cart.credits"
                values={{
                  credits: userCredits,
                  bold: text => <strong>{text}</strong>,
                }}
              />
            </CreditsText>
          </CreditsDisplayContainer>
        )}
        {canShowMobileCart && (
          <ToggleMenuContainer>
            {hasCredits && (
              <CreditsDisplayCart>
                <CreditsDisplayCartText>
                  <FormattedMessage
                    entry="cart.credits"
                    values={{
                      credits: userCredits,
                      bold: text => <strong>{text}</strong>,
                    }}
                  />
                </CreditsDisplayCartText>
              </CreditsDisplayCart>
            )}
            <ToggleMenu
              onClick={handleMobileCartClick}
              data-testid="menu-toggle"
            >
              <CartText>
                {canMobileCartGoDirectToCheckout
                  ? mobileCheckoutText
                  : mobileCartText}
              </CartText>
              <CartTextSmall>
                <FormattedMessage
                  entry="cart.subtotal"
                  values={{ value: cartSubtotal }}
                />
              </CartTextSmall>
            </ToggleMenu>
          </ToggleMenuContainer>
        )}
        <MenuItemModalContainer />
        <PerkConfirmModal />
        <CartModal isOpen={isCartOpen} onRequestClose={requestCartClose} />
        <GroupOrderJoinModal />
        <GroupOrderDetailsModal />
        <GroupOrderLeaveModal />
        <RemovedFromGroupModal />
        <MissedGroupOrderModal />
        <GroupOrderNonjoinableModal />
        <GroupOrderHostInfoModal />
        <GroupOrderGuestInfoModal />
        <GroupOrderInviteModal />
        <DownloadAppModal
          isOpen={isDownloadAppModalOpen}
          onClose={onDownloadAppModalClose}
          merchantId={merchantId}
        />
        <ExpressServiceModal />
        <ServiceFeeModal />
      </ThemeProvider>
    </Page>
  );
};

MenuContainer.propTypes = {
  isPreview: PropTypes.bool,
  overrideMerchantName: PropTypes.string,
  overrideMerchantAddress: PropTypes.string,
};

export default MenuContainer;
