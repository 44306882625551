import PropTypes from 'prop-types';
import { PERK_DISPLAY_HINT, PERK_TYPE } from '@artemis/utils/constants';

export const AvailabilityShape = PropTypes.shape({
  isAvailable: PropTypes.bool,
});

export const PerkShape = PropTypes.shape({
  originalPrice: PropTypes.string,
  savings: PropTypes.string,
  discountedPrice: PropTypes.string,
  percentOff: PropTypes.number,
  perkType: PropTypes.oneOf(Object.keys(PERK_TYPE)),
  displayHint: PropTypes.oneOf(Object.keys(PERK_DISPLAY_HINT)),
  fundingSource: PropTypes.string,
  menuHighlightMessage: PropTypes.string,
  primaryMessage: PropTypes.string,
});

export const CalorieRangeShape = PropTypes.shape({
  minCalories: PropTypes.number,
  maxCalories: PropTypes.number,
});

export const MenuItemShape = PropTypes.shape({
  title: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.string,
  imageUrl: PropTypes.string,
  availability: AvailabilityShape,
  perk: PerkShape,
  calorieRange: CalorieRangeShape,
});

export const OptionShape = PropTypes.shape({
  title: PropTypes.string,
  price: PropTypes.string,
  selected: PropTypes.bool,
  minIncrementCount: PropTypes.number,
  maxIncrementCount: PropTypes.number,
  freeQuantity: PropTypes.number,
  defaultIncrementCount: PropTypes.number,
  incrementNumerator: PropTypes.number,
  incrementDenominator: PropTypes.number,
});

export const ChoiceShape = PropTypes.shape({
  title: PropTypes.string,
  numSelected: PropTypes.number,
  needsMoreSelected: PropTypes.bool,
  id: PropTypes.string,
  minSelectable: PropTypes.number,
  maxSelectable: PropTypes.number,
  freeQuantity: PropTypes.number,
  options: PropTypes.arrayOf(OptionShape),
  selectedTotalPriceMicro: PropTypes.number,
});
